/* animations.css */
@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @keyframes float-slow {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes float-slower {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
  }
  
  @keyframes ping-slow {
    0% { transform: scale(1); opacity: 1; }
    75%, 100% { transform: scale(2); opacity: 0; }
  }
  
  @keyframes ping-slower {
    0% { transform: scale(1); opacity: 1; }
    75%, 100% { transform: scale(2.5); opacity: 0; }
  }
  
  @keyframes pulse-slow {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 0.2; }
  }
  
  @keyframes pulse-slower {
    0%, 100% { opacity: 0.4; }
    50% { opacity: 0.1; }
  }
  
  @keyframes spin-slow {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .animate-blink {
    animation: blink 1s infinite;
  }
  
  .animate-float-slow {
    animation: float-slow 4s infinite;
  }
  
  .animate-float-slower {
    animation: float-slower 6s infinite;
  }
  
  .animate-ping-slow {
    animation: ping-slow 3s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  
  .animate-ping-slower {
    animation: ping-slower 4s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  
  .animate-pulse-slow {
    animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  .animate-pulse-slower {
    animation: pulse-slower 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  .animate-spin-slow {
    animation: spin-slow 8s linear infinite;
  }